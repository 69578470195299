<template>
  <div v-lazy:background-image="bgimg" class="bg">
    <div class="inv-top mb15">
      <van-image
        round
        width="1rem"
        height="1rem"
        fit="cover"
        v-if="invitation.user_info.avatar"
        :src="invitation.user_info.avatar"
      />
      <div class="name text-white ml10 font-14">{{invitation.user_info.nickname}}</div>
    </div>
    <div class="inv-cen mt10 mb10">
      <p
        class="name text-theme3 mt10 font-18"
        v-if="invitation.activity_info.activity_name"
      >{{invitation.activity_info.activity_name}}</p>
      <p
        class="name mt10 text-white font-16"
        v-if="invitation.activity_info.start_time"
      >{{invitation.activity_info.start_time}}</p>
      <p
        class="name mt5 text-white font-16"
        v-if="invitation.activity_info.enroll_type_text"
      >{{invitation.activity_info.enroll_type_text}}</p>
    </div>
    <div class="inv-bot">
      <div>
        <p class="text-white font-12">长按识别二维码</p>
        <div id="qrcode" ref="qrcode">
          <van-image :src="invitation.url" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Image } from "vant";
import QRcode from "qrcodejs2";
import { activity_code } from "@/request/api";
import wx from "@/request/utils/wechat";
import cache from '@/request/utils/cache';
import store from '@/store/index';

export default {
  components: {
    [Image.name]: Image,
    QRcode
  },
  data() {
    return {
      bgimg: '',
      invitation: {
        url: "", //二维码
        value: "", //链接
        user_info: {
          avatar: "",
          nickname: ""
        },
        activity_info: {
          activity_name: "",
          start_time: "",
          enroll_type: ""
        },
        spo_info: {}
      }
    };
  },
  mounted() {
    // this.getQrCode();
	var systemInfo = cache.get('systemInfo');
	if (!systemInfo){
		getsysteminfo({}).then(res => {
		    if (res.code == 1) {
		        store.commit('UpdateSystemInfo', res.data)
				this.bgimg = res.data.inviteBg
		    }
		})
	}else{
		systemInfo = JSON.parse(systemInfo)
		this.bgimg = systemInfo.inviteBg
		
	}
	
    this.showLoading("加载中", true);

    activity_code({
      activity_id: this.$route.query.id
    })
      .then(res => {
        this.hideLoading();
        if (res.code == 1) {
          this.invitation = res.data;

          //初始化分享
          let shareData = {
            title: res.data.activity_info.activity_name,
            desc: res.data.user_info.nickname+'邀请你',
            img:res.data.url,
            url:res.data.value
          };
         
          wx.share(shareData);
          
        } else {
          this.$dialog({ message: res.msg });
        }
      })
      .catch(err => {
        this.hideLoading();
        this.$dialog({ message: "请求有误,请重新刷新页面" });
      });
  },
  methods: {
  }
};
</script>

<style lang="less">
.bg {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
}

.inv-top {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 172px;
}

.inv-bot {
  margin-top: 30px;
}

#qrcode {
  width: 180px;
  height: 180px;
  display: inline-block;
  img {
    width: 132px;
    height: 132px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
  }
}
</style>